import React from "react";
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-auto-translate";
// import loggedInHeader from "../../assets/banner_home_image.jpg";
import loggedInHeader from "../../assets/fbeelding home pagina lichte onderkant.png";
import { makeStyles } from "@material-ui/core/styles";
import MainCategoryOverview from "../../components/MainCategoryOverview";
import BestSellers from "../../components/BestSellers";

const styles = makeStyles(theme => ({
  itemContainer: {
    width: "100%",
  },
  contentContainer: {
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "500",
    // backgroundColor: "#F0F0F0",
    color: "#333333",
    "@media (max-width: 600px)": {
      minHeight: 250
     },
  },
}));

export default function UsersLanding(props) {
  const classes = styles();
  return (
    <Grid container className={classes.itemContainer}>
      <Grid container>
      <Grid xs={false} md={1} />
      <Grid item xs={12} sm={12} md={10} xl={10} className={classes.imageContainer}>

        <Grid container className={classes.imageContainer} style={{ marginTop: '5px' }}>
          <Grid item xs={12} sm={6} md={6} >
            <img src={loggedInHeader} style={{width: "100%"}} overlay={false} />
          </Grid>

          <Grid item xs={12} sm={6} md={6} className={classes.contentContainer}>
            <Translate>Welkom op de online geschenkenmarkt!</Translate>
            <br/><br/>
            <Translate>Hier stel je jouw cadeaupakket samen.</Translate>
            <br/>
            <Translate>Klik op de kramen en ga op zoek naar</Translate>
            <br/>
            <Translate>jouw favoriete producten.</Translate>
            <br/><br/>
            <Translate>Veel shopplezier!</Translate>
          </Grid>
        </Grid>
      </Grid>
      </Grid>

      <Grid xs={false} md={1} />
      <Grid xs={12} md={10} >
        <MainCategoryOverview />
        {/* <BestSellers />    Remove section popular products */}
      </Grid>
    </Grid>
  );
}
