import { 
    SET_SELECTED_CATEGORY, 
    ADD_TO_CATEGORY_HISTORY, 
    GO_BACK_CATEGORY,
    CLEAR_CATEGORY_HISTORY 
} from "../actions/categoryActions";

const initialState = {
    selectedCat: '',
    categoryHistory: [],
};

export default function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SELECTED_CATEGORY:
            return {
                ...state,
                selectedCat: action.payload,
            };

        case ADD_TO_CATEGORY_HISTORY:
            const updatedHistory = [...state.categoryHistory, action.payload];
            return {
                ...state,
                categoryHistory: updatedHistory,
            };

        case GO_BACK_CATEGORY:
            if (state.categoryHistory.length > 0) {
                const newHistory = state.categoryHistory.slice(0, -1);
                return {
                    ...state,
                    selectedCat: newHistory[newHistory.length - 1] || null,
                    categoryHistory: newHistory,
                };
            } 
            return state;

        case CLEAR_CATEGORY_HISTORY:
            return {
                ...state,
                selectedCat: null,
                categoryHistory: [],
            };

        default:
            return state;
    }
}
