export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const ADD_TO_CATEGORY_HISTORY = "ADD_TO_CATEGORY_HISTORY";
export const GO_BACK_CATEGORY = "GO_BACK_CATEGORY";
export const CLEAR_CATEGORY_HISTORY = "CLEAR_CATEGORY_HISTORY";

export const setSelectedCategory = (categoryId) => ({
    type: SET_SELECTED_CATEGORY,
    payload: categoryId,
});

export const addToCategoryHistory = (categoryId) => ({
    type: ADD_TO_CATEGORY_HISTORY,
    payload: categoryId,
});

export const goBackCategory = () => ({
    type: GO_BACK_CATEGORY,
});

export const clearCategoryHistory = () => ({
    type: CLEAR_CATEGORY_HISTORY,
});
