import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useRouteMatch, useHistory } from "react-router-dom";
import MainLogo from "./MainLogo";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { Link as RouterLink } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";
import MenuCart from "./MenuCart";
import SearchBar from "./SearchBar";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import LogoutButton from "./HeaderHamburger";
import TranslationFlag from "./TranslationFlag";
import { useQuery } from "@apollo/react-hooks";
import { CUSTOMER_BY_CODE } from "../queries-mutations/customers";
import { connect, useDispatch } from "react-redux";
import { Translate } from "react-auto-translate";
import { clearCategoryHistory } from "../actions/categoryActions";

function UsersHeaderMenu(props) {
  const [duiten, setDuiten] = useState(props.userInformation.duiten);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef(null); // Ref for header element
  const classes = headerMenu();
  let match = useRouteMatch();
  const history = useHistory();
  const { loading, error, data, refetch } = useQuery(CUSTOMER_BY_CODE, {
    variables: { code: props.userInformation.code },
  });

  const theme = useTheme();
  const dispatch = useDispatch();
  const isXxsScreen = useMediaQuery('(max-width:390px)');
  const isSmUp =  useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const calculateDuiten = () => {
      const totalCartPrice = props.cart.reduce((acc, item) => {
        const price = parseFloat(item.price);
        return acc + (isNaN(price) ? 0 : price);
      }, 0);
      const availableDuiten = parseFloat(props.userInformation.duiten);
      if (!isNaN(availableDuiten) && !isNaN(totalCartPrice)) {
        const remainingDuiten = availableDuiten - totalCartPrice;
        setDuiten(remainingDuiten);
      } else {
        console.error(
          "Invalid duiten or totalCartPrice value",
          availableDuiten,
          totalCartPrice
        );
      }
    };

    calculateDuiten();
  }, [props.cart, props.userInformation.duiten]);

  useEffect(() => {
    // const unlisten = history.listen( async () => {
    let path = `${match.path}/bestelling-geslaagd`;
    const fetchData = async () => {
      if (history.location.pathname === path) {

        await refetch();

        if (data) {

          // setDuiten(data.customerByCode.duiten);
        }
      }
    };

    fetchData();
    // });

    const unlisten = history.listen(async () => {
      if (history.location.pathname === path) {

        await refetch();
        if (data) {

          // setDuiten(data.customerByCode.duiten);
        }
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    // Update header height on mount and when the window resizes
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };
    updateHeaderHeight();
    window.addEventListener("resize", updateHeaderHeight);
    return () => window.removeEventListener("resize", updateHeaderHeight);
  }, []);

  return (
    <>
      <Grid
        container
        ref={headerRef}
        style={{
          "@media (max-width: 898px)": {
            justifyContent: "space-between",
          },
          padding: "12px 0",
          overflowX: "hidden",
          background: "#F0F0F0",
          position: "fixed",
          zIndex: 100,
        }}
      >
        {/* desktop */}
        <Grid item xs={false} md={1} />
        <Grid item xs={2} sm={2} md={1} onClick={
          () => {
            dispatch(clearCategoryHistory())
          }
        } component={RouterLink}
          className={classes.applogo}
          to={userRoutes.SHOP}
          // style={{ margin: "auto 0px" }}
          style={{ paddingRight: "12px" }}
        >
          <MainLogo />
        </Grid>

        <Grid item xs={6} className={classes.hideOnLargeScreen} />
        <Grid item xs={6} sm={7} style={{ margin: "auto 0px" }}
          className={classes.hideOnSmallScreen}
        >
          <Typography style={{ float: "left" }}>
            <Translate>
              Welkom{" "}
              {props.userInformation.firstName +
                " " +
                props.userInformation.lastName}
              !
            </Translate>
          </Typography>
          <Typography >
            <Translate>{duiten != 1? "Duiten" : "Duit"} te besteden:</Translate>{" "}
            <Translate>{duiten}</Translate>
          </Typography>
        </Grid>


        <Grid item xs={4} sm={3} className={classes.hideOnDesktop}>
          <Grid container style={{ justifyContent: "space-around", alignItems: "center", height: 55 }}>
          {isSmUp ? ( <>
            <Grid item >
              <TranslationFlag />
            </Grid>

            <Grid item >
              <MenuCart />
            </Grid>

            <Grid item >
              <LogoutButton />
            </Grid>
            </>
          ) : (<>
            <Grid item style={{ paddingBottom: 3 }}>
              <LogoutButton />
            </Grid>

            <Grid item >
              <TranslationFlag />
            </Grid>

          </>)}
          </Grid>
        </Grid>


        {/* desktop */}
        <Grid item xs={9} md={9} className={classes.hideOnMobile} >
          <Grid container style={{ justifyContent: "right", alignItems: "center", margin: "auto 0px" }}  >
            <Grid className={classes.lastSection}>
              <Grid
                container
                style={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <Typography>
                  <Translate>
                    {`Welkom ${props.userInformation.firstName} ${props.userInformation.lastName}! je hebt nog: `}
                    <u>{`${duiten} ${duiten != 1 ? 'Duiten' : 'Duit'} te besteden`}</u>
                  </Translate>
                </Typography>

                <Grid style={{ width: "250px" }}>
                  <SearchBar />
                </Grid>
                <Grid >
                  <TranslationFlag />
                </Grid>
                <Grid >
                  <MenuCart />
                </Grid>

                <Grid >
                  <LogoutButton />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid >


         {/* mobile screen */}
        <Grid item xs={12} className={classes.hideOnLargeScreen}>
          <div className={classes.searchContainer} style={{ display: 'flex', justifyContent: isXxsScreen ? 'left' : 'center', alignItems: 'center', position: 'relative' }}>

            {/* Centered Welcome Text */}
            <div style={{ textAlign: 'center' }}>
              <Typography style={{ width: 'fit-content' }}>
                <Translate>
                  {`Welkom ${props.userInformation.firstName} ${props.userInformation.lastName}!`}
                </Translate>
              </Typography>
              <Typography style={{ width: 'fit-content' }}>
                <Translate>
                  {`je hebt nog: `}
                  <u>{`${duiten} ${duiten != 1? 'Duiten' : 'Duit'} besteden`}</u>
                </Translate>
              </Typography>
            </div>

            {/* Right-aligned Menu Cart */}
            <div style={{ position: 'absolute', right: 15 }}>
              <MenuCart />
            </div>
          </div>
        </Grid>


        <Grid item xs={false} md={1} className={classes.hideOnDesktop} />
        <Grid item xs={12} md={11} className={classes.hideOnDesktop}>


          <div className={classes.searchContainer}>
            <SearchBar />
          </div>
        </Grid>
      </Grid>

      <Grid container style={{ paddingTop: headerHeight }}>
      </Grid>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { cart } = state;
  const { userInformation } = state;
  return { cart, userInformation, ownProps };
};

export default connect(mapStateToProps)(UsersHeaderMenu);

const headerMenu = makeStyles((theme) => ({

  searchContainer: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "95%",
    margin: "5px auto",
    border: 0,
    backgroundColor: "transparent",
    borderRadius: 3,
    "@media (max-width: 1200px) and (min-width: 956px)": {
      margin: "5px 0",
      width: "calc(100% - 25px)",
      padding: "unset",
    },
    "@media (max-width: 956px)": {

      margin: "5px 0",

      width: "calc(100% - 25px)",
      padding: "0 0 0 12px",
    },

  },
  searchInput: {
    width: "90%",
    boxShadow: "none",
  },
  searchIcon: {},
  genericIcon: {
    fontSize: 30,
  },
  hideOnMobile: {
    "@media (max-width: 1198px)": {
      display: "none",
    },
  },
  hideOnDesktop: {

    "@media (min-width: 1199px)": {
      display: "none",
    },
  },
  hideOnSmallScreen: {
    "@media (max-width: 599px)": {
      display: "none",
    },
    "@media (min-width: 1199px)": {
      display: "none",
    },
  },
  hideOnLargeScreen: {
    "@media (min-width: 600px)": {
      display: "none",
    },
  },
  lastSection: {
    width: "900px",
    "@media (min-width: 1500px)": {
      width: "1000px",
    },
  },
  firstSection: {
    width: "450px",
    "@media (min-width: 1500px)": {
      width: "520px",
    },
  },
  applogo: {
    height: "55px",
    textAlign: "left",
    // "@media (min-width: 600px)": {

    // },
    "@media (max-width: 956px)": {
      paddingLeft: "12px"
    },
    "@media (max-width: 956px) and (min-width: 600px)": {
      paddingRight: "12px"
    },
  },
  snackbarPaper: {
    zIndex: 100,
  },
}));
