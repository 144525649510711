import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import SingleProductCart from "../../components/SingleProductCart";
import Button from "@material-ui/core/Button";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {   CUSTOMER_BY_CODE,  PLACE_ORDER,  ORDERS_BY_CUSTOMER_CODE 
} from "../../queries-mutations/customers";
import { removeFromCart, updateToCart } from "../../actions";
import Grid from "@material-ui/core/Grid";
import DuitenIcon from "../../components/DuitenIcon";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { userRoutes } from "./constants";
import CartSuggestions from "../../components/CartSuggestions";
import BackButton from "../../components/BackButton";
import ConfirmationRequiredButton from "../../components/ConfirmationRequiredButton";
import { Translate } from "react-auto-translate";
import { products, PRODUCTS_SHOP, ADMIN_OVERVIEW_PRODUCTS } from "../../queries-mutations/products";
import { setDuiten } from "../../actions";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const styles = makeStyles(theme => ({
  container: {
    padding: '25px 0',
    [theme.breakpoints.up('md')]: {
      padding: '25px 25px 25px 0',
    },
  },
  rightText: {
    textAlign: "Left"
  },
  rightItem: {
    width: "100%",
    marginBottom: 15
  },
  paper: {
    padding: '12px 0',
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
    width: "100%",
    backgroundColor: '#F0F0F0'
  },
  gridContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 30
  },
  previousContainer: {

    marginBottom: 25
  },
  headerTitle: {
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "left",


  },
  smallScreenText: {
    "@media (max-width: 956px)": {
      paddingLeft: "10px"
    }
  },

  warning: {
    color: "red",
    margin: "10px auto"
  },
  warningSmall:{
       
     "@media (max-width: 600px)": {
      textAlign: "left"
     }
  },
  rightSentence: {
    marginBottom: 12,
    textAlign: "Left",
    wordWrap: "break-word"
  }
}));

function Cart(props) {

  let history = useHistory();
  const isMounted = useRef(true);
  const firstPaperRef = useRef(null);
  const secondPaperRef = useRef(null);
  const [paperHeight, setPaperHeight] = useState(400); // Default height
  const [initiatedOrder, setInitiatedOrder] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [stockLength, setStockLength] = useState(false);
  const [addCarts, setaddCarts] = useState([]);


  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const initiateOrder = () => {
    setInitiatedOrder(true);

  };

  const cancelOrder = () => {
    setInitiatedOrder(false);
  };

  useEffect(() => {
    // Update height when component mounts or `props.cart` changes
    if (firstPaperRef.current) {
      setPaperHeight(firstPaperRef.current.offsetHeight - 23);
    }
    window.scrollTo(0, 0);
  }, [props.cart]);

  useEffect(() => {
    // Update the second Paper height
     const screenWidth = window.innerWidth;
     if(screenWidth > 600){
      if (secondPaperRef.current) {

        if (!isMdUp && paperHeight < 500) {
          secondPaperRef.current.style.minHeight = `500px`;
  
        } else {
          secondPaperRef.current.style.minHeight = `${paperHeight}px`;
  
        }
  
  
      }
     }
    
  }, [paperHeight]);

  useEffect(() => {

    let output = props.cart.map((item) => ({
      ...item,
      stock: false,
    }));
    setaddCarts(output)

    return () => {
      isMounted.current = false;
    };
  }, []);

  const confirmOrder = () => {
    setbtnDisabled(true);

    setTimeout(() => {
      handleBestellen();
    }, 1000);

  };

  const classes = styles();

  const [bestellingStatus, setBestellingStatus] = useState(<div />);

  const [bezig, setBezig] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');

  const { loading, error, data, refetch } = useQuery(CUSTOMER_BY_CODE, {
    variables: { code: props.userInformation.code }
  });

  const { loading: OrderLoading, error: OrderError, data: OrderData, refetch: orderRefetch } = useQuery(ORDERS_BY_CUSTOMER_CODE, {
    variables: { code: props.userInformation.code }
  });

  const {
    data: data_pro,
    error: error_pro,
    loading: loading_pro,
    refetch: refetch_pro
  } = useQuery(ADMIN_OVERVIEW_PRODUCTS, {
    returnPartialData: false, errorPolicy: "ignore"
  }, { errorPolicy: "all" });

  const [placeOrder] = useMutation(PLACE_ORDER);

  const search = (nameKey, myArray) => {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].id === nameKey) {
        return myArray[i];
      }
    }
  };

  const handleClick = (names) => {
    setAlertMessage(names);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setStockLength(false);
  };



  function removeDuplicates(array) {
    const uniqueIds = new Set();
    return array.filter((item) => {
      const isDuplicate = uniqueIds.has(item.id);
      uniqueIds.add(item.id);
      return !isDuplicate;
    });
  }

  const handleBestellen = async () => {
    setBezig(true);

    if (data) {
    
      let duiten = data.customerByCode.duiten;
      let totaal = props.cart
        .map(o => o.price)
        .reduce((a, c) => {
          return a + c;
        });
      if (totaal > duiten) {
     
        setBestellingStatus(
          <Typography className={classes.warning}>
            <Translate>Je hebt niet genoeg duiten!</Translate>
          </Typography>
        );
      } else {


        let products = [];
        let out = false;
        props.cart.map(product => {
          products.push(product.id);
        });

        products.map(productID => {
          let found = [];
          products.map(counter => {
            if (counter === productID) {
              found.push(counter);
            }
          });
     

          let checkProduct = data_pro && data_pro.products ? search(productID, data_pro.products) : null;


        });
       
        if (out) {
          setBestellingStatus(
            <Typography className={classes.warning}>
              <Translate>
                Een van de producten die je hebt gekozen is helaas op, kies
                alsjeblieft iets anders
              </Translate>
            </Typography>
          );
        } else {
        
          var resultPlaceOrder = await placeOrder({
            variables: {
              customer: data.customerByCode.id,
              products: products
            }
          });
        
          if (resultPlaceOrder.data.placeOrder.success) {

            props.cart.forEach((product, index) => {
 

              props.removeCard(0);

            });

            props.updateDuiten(0)

            if (isMounted.current) {

              await orderRefetch();

              if (OrderData) {
   
              }

              history.push(userRoutes.SUCCESS);
            } 

          } else {  // out of stock
 
            let outOfStock = resultPlaceOrder.data.placeOrder.products
            const uniqueOutOfStock = removeDuplicates(outOfStock);
            const inStock = uniqueOutOfStock.filter((item) => item.stock > 0);
            const isStock = outOfStock.filter((item) => item.stock == 0);
   
            setbtnDisabled(false);
            setBezig(false);
            let namelist = uniqueOutOfStock.map(item => item.name);



            let output = props.cart.map((item, index) => {
      
              if (isStock.some((v) => v.id === item.id)) {

                props.updateCart(item.id, index)
              }
              return item; // No match, return the original item
            });

            setaddCarts(output)

            if (inStock.length === 0) {
              handleClick(namelist.join(', '));
              setOpen(true);
            } else {

              const stocklist = inStock.map((product) => {
                return `${product?.name} product available stock is ${product.stock},`;
              });
    
              handleClick(stocklist)
              setStockLength(true);
            }
          }

        }
      }
    } else if (loading) {
   
      return (
        <div>
          <CircularProgress color="secondary" />
        </div>
      );
    } else if (error) {
      return (
        <div>
          <h2>
            <Translate>
              Er ging iets fout, probeer het later opnieuw
            </Translate>
          </h2>
        </div>
      );
    }

    setBezig(false);
  };

  if (props.cart.length > 0) {
    return (
      <div>
        <Snackbar open={open} onClose={handleClose}>
          <Alert style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }} onClose={handleClose} severity="error">
            <Translate> U kunt helaas geen order plaatsen want, ons artikel <strong>&nbsp;{alertMessage}&nbsp;</strong> is uit onze voorraad, kies een ander artikel. </Translate>
          </Alert>
        </Snackbar>

        <Grid container >
          <Grid xs={false} md={1} />

          <Grid container xs={12} md={10} xl={10}>
            <Grid item xs={12}>
              <Grid container className={classes.gridContainer}>
                <Grid item xs={12} md={10} className={`${classes.headerTitle} ${classes.smallScreenText}`}>
                  <h2 style={{ fontSize: '28px', color: "#0D7C5F", margin: 0}}>
                    <Translate >Maak je bestelling compleet</Translate>
                  </h2>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} >
              <Grid item xs={12} md={6} className={classes.container} >
                <Paper elevation={2} className={classes.paper} style={{ width: isMdUp ? "calc(100% - 20px)" : '100%', marginRight: isMdUp ? '20px' : 0, height: 'fit-content', minHeight: '480px', backgroundColor: 'white' }} ref={firstPaperRef}>
                  <Typography className={[classes.rightText, classes.smallScreenText]}>
                    <h2>
                      <Translate>Door jou gekozen items</Translate>
                    </h2>
                  </Typography>
                  {props.cart.map((product, index) => (
                    <SingleProductCart
                      index={index}
                      name={product?.name}
                      id={product.id}
                      img={product.img}
                      price={product.price}
                      stock={product.stock}
                    />
                  ))}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} className={classes.container}>
                <Paper elevation={2} className={classes.paper} style={{  width: isMdUp ? "calc(100% - 20px)" : '100%', marginLeft: isMdUp ? '20px' : 0, height: isMdUp && '480px' }} ref={secondPaperRef}>
                  <Grid container justify-content="flex-start" alignItems="flex-start" className={classes.smallScreenText}>
                    <Grid item xs={12}>
                      <Typography className={`${classes.rightText}  `}>
                        <h2>
                          <Translate>Bestellen</Translate>
                        </h2>
                      </Typography>
                      <Typography className={`${classes.rightText}  `}>
                        <p style={{ marginBottom: 12}}>
                          <Translate>
                            Je bestelling wordt bij je werkgever geleverd.
                          </Translate>
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.rightSentence}>
                        <Translate>Bedrijf:</Translate>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={classes.rightSentence}>
                        {data ? data.customerByCode.company?.name : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.rightSentence}>
                        <Translate>Naam:</Translate>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={classes.rightSentence}>
                        {data ? data.customerByCode.firstName : ""}{" "}
                        {data &&
                          data.customerByCode.insertion &&
                          data.customerByCode.insertion.length > 0
                          ? `${data.customerByCode.insertion} `
                          : ""}
                        {data ? data.customerByCode.lastName : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.rightSentence}>
                        <Translate>Email:</Translate>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={classes.rightSentence}>
                        {data ? data.customerByCode.email : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.rightSentence}>Code:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={classes.rightSentence}>
                        {data ? data.customerByCode.code : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.rightSentence}>
                        <Translate>Te besteden duiten:</Translate>
                      </Typography>
                    </Grid>
         
                    <Grid item xs={8} container alignItems="center" style={{ textAlign: "Left" }}>
                      <Typography className={classes.rightSentence} style={{ marginRight: '5px', marginBottom: '2px' }}>
                        {data ? data.customerByCode.duiten : "0"}{" "}
                      </Typography>
                      <DuitenIcon />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.rightSentence}>
                        <Translate>Totaal in winkelmandje:</Translate>
                      </Typography>
                    </Grid>
        
                    <Grid item xs={8} container alignItems="center" style={{ textAlign: "Left" }}>
                      <Typography className={classes.rightSentence} style={{ marginRight: '5px', marginBottom: '2px' }}>
                        {props.cart
                          .map(o => o.price)
                          .reduce((a, c) => {
                            return a + c;
                          })}{" "}
                      </Typography>
                      <DuitenIcon />
                    </Grid>

                    <Grid items xs={12}>
                      {bestellingStatus}
                    </Grid>
                    <Grid item xs={12}>
                      {props.cart
                        .map(o => o.price)
                        .reduce((a, c) => {
                          return a + c;
                        }) > (data ? parseInt(data.customerByCode.duiten) : 0) ? (
                        <Typography className={`${classes.warning} ${classes.warningSmall}`}>
                          <Translate >
                            Momenteel heb je meer producten in je winkelmandje dan de Duiten  die je te besteden hebt, verwijder enkele producten om je bestelling af te ronden.
                          </Translate>
                        </Typography>
                      ) : props.cart
                        .map(o => o.price)
                        .reduce((a, c) => {
                          return a + c;
                        }) !== (data ? parseInt(data.customerByCode.duiten) : 0) ? (
                        <Typography className={`${classes.warning} ${classes.warningSmall}`}>
                          <Translate >
                            Je hebt nog niet al je Duiten opgemaakt, om de bestelling compleet te maken voeg je artikelen toe
                          </Translate>
                        </Typography>
                      ) : (
                        <Typography>Bevestig je bestelling</Typography>
                      )}
                      {props.cart
                        .map(o => o.price)
                        .reduce((a, c) => {
                          return a + c;
                        }) > (data ? parseInt(data.customerByCode.duiten) : 0) ? (
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={true}
                         
                        >
                          <Translate>Bestellen</Translate>
                        </Button>
                      ) : props.cart
                        .map(o => o.price)
                        .reduce((a, c) => {
                          return a + c;
                        }) !== (data ? parseInt(data.customerByCode.duiten) : 0) ? (
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={true}
                           style={{ color:"white"}}
                        >
                          <Translate>Bestellen</Translate>
                        </Button>
                      ) : (
                        <ConfirmationRequiredButton
                          disabled={btnDisabled}
                          initiated={initiatedOrder}
                          cancel={cancelOrder}
                          initiate={initiateOrder}
                          confirm={confirmOrder}
                          confirmationMessage={
                            "Weet je zeker dat je deze bestelling wil plaatsen?"
                          }
                          cancelMessage={"Annuleren"}
                          confirmMessage={"Bestellen"}
                          initialMessage={"Bestellen"}
                          white={true}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

            </Grid>

            {data &&
              parseInt(data.customerByCode.duiten) -
              props.cart
                .map(o => o.price)
                .reduce((a, c) => {
                  return a + c;
                }) >
              0 &&
              parseInt(data.customerByCode.duiten) -
              props.cart
                .map(o => o.price)
                .reduce((a, c) => {
                  return a + c;
                }) <=
              1 && (
                
                <Grid item xs={12} className={classes.container}>
                  <Paper elevation={2} className={classes.paper}>
                    <>
                      <CartSuggestions userInformation={props.userInformation} cart={props.cart} />
                    </>
                  </Paper>
                </Grid>
            
              )}

            <Grid item xs={6} className={`${classes.previousContainer} ${classes.smallScreenText}`} style={{ marginTop: isMdUp ? '15px' : 0 }}>
              <BackButton />
            </Grid>
          </Grid>

        </Grid>
      </div>

    );
  } else {
    return (
      <Grid container >
      <Grid item xs={false} md={1} />


      <Grid item xs={12} md={10} xl={10}>
      <Paper style={{ boxShadow: "unset" , marginTop: "20px"}}>
    
        {/* <Grid xs={12} md={11} xl={10} className={classes.previousContainer} style={{ marginTop: isMdUp ? '15px' : 0 }}> */}
          <BackButton />
        {/* </Grid> */}
        <h1>
          <Translate>Er zit niks in je winkelwagen</Translate>
        </h1>
      </Paper>
      </Grid>
      </Grid>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const { cart } = state;
  const { userInformation } = state;
  return { cart, userInformation, ownProps };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDuiten: (val) => {
      dispatch(setDuiten(val));
    },
    removeCard: (item) => {
      dispatch(removeFromCart(item));

    },
    updateCart: (id, index) => {

      dispatch(updateToCart(id, index));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
