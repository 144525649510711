import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";

export default function ProductenTable(props) {
  const [tableData, setTableData] = useState([]);

  const getUniqueCategories = (products) => {
    const categories = products.flatMap(product => product.categories.map(category => category.name));
    return [...new Set(categories)];
  };

  const uniqueCategories = getUniqueCategories(props.data);

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        filterType: "textField"
      }
    },
    {
      name: "order",
      label: "order",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "name",
      label: "Naam",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "code",
      label: "Code",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "description",
      label: "Beschrijving",
      options: {
        filter: false,
        display: false
      }
    },
    {
      name: "categories",
      label: "Categorieen",
      options:{
        filter: true,
        filterType: 'dropdown',
        filterOptions: {
          names: uniqueCategories,
          logic(categories, filters) {
            // Logic to filter products based on selected categories
            if (filters.length === 0) return true;
            return filters.every(filter => !categories.includes(filter));
          }
        },
        customBodyRender: (value) => {
          return value;
        }
      }
    },
    {
      name: "price",
      label: "Prijs",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "stockAmount",
      label: "Voorraad",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "cartSuggestion",
      label: "Winkelwagen suggestie",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "featured",
      label: "Uitgelicht",
      options: {
        filterType: "multiselect"
      }
    }
  ];

  const processProductData = async () => {
    console.log("processProductData called");
    const promises = props.data.map(async (product) => {
      return {
        id: product.id,
        order: product.order,
        name: product.name,
        description: product.description.replace(/(<([^>]+)>)/ig, ''),
        categories: product.categories.map(category => category.name).join(", "),
        price: product.price,
        code: product.code,
        stockAmount: product.stockAmount,
        cartSuggestion: product.cartSuggestion ? "Ja" : "Nee",
        featured: product.featured ? "Ja" : "Nee"
      };
    });

    const resolvedTableData = await Promise.all(promises);
    setTableData(resolvedTableData);  // Update state with processed data
  };
  
  useEffect(() => {
    processProductData();
  }, [props.data]);

  const onRowClick = selected => {
    const selectedProduct = props.data.find(
      product => product.id === selected[0]
    );
    props.onRowClick(selectedProduct);
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: onRowClick
  };

  return (
    <MUIDataTable
      title={props.title}
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}
