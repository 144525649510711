import React, { useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { Editor } from "@tinymce/tinymce-react";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_IMAGE,
  DELETE_PRODUCT,
} from "../queries-mutations/products";
import { editModalStyle } from "../styles/editModalStyle";
import ImageUploader from "react-images-upload";
import ImageUpload from "../helpers/ImageUpload";
import AdminImageDisplay from "./AdminImageDisplay";
import { trackPromise } from "react-promise-tracker";
import ConfirmationRequiredButton from "./ConfirmationRequiredButton";
import { PinDropRounded } from "@material-ui/icons";
import { ADD_BANNER_TO_CATEGORY, ADD_IMAGE_TO_BANNER, CREATE_BANNER, DELETE_BANNER, REMOVE_BANNER_FROM_CATEGORY, UPDATE_BANNER } from "../queries-mutations/banners";
import CategoriesSelector from "./CategoriesSelector";
import { FormControl, FormLabel } from "@material-ui/core";
import { Translate } from "react-auto-translate";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { tinyKey } from "../Keys/tiny";


export default function EditBannerModal(props) {
  const [state, setState] = useState({
    description: props.banner.description ? props.banner.description : "",
    image: props.banner.image ? props.banner.image : "",
    activeStatus: props.banner.activeStatus ? props.banner.activeStatus : false,
    selectedCategories: props.banner.categories
      ? props.banner.categories
      : [],
    addedCategories: [],
    removedCategories: [],
  });

  const classes = editModalStyle();

  const [updateBanner] = useMutation(UPDATE_BANNER);
  const [createBanner] = useMutation(CREATE_BANNER);
  const [createImage] = useMutation(CREATE_IMAGE);

  const [addBannerToCategory] = useMutation(ADD_BANNER_TO_CATEGORY);
  const [removeBannerFromCategory] = useMutation(REMOVE_BANNER_FROM_CATEGORY);

  const [deleteBanner] = useMutation(DELETE_BANNER);

  const [isPreview, setIsPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [errors, setErrors] = useState(false);
  const imgRef = useRef(null);
  const [isCropper, setIsCropper] = useState(false);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({
    unit: 'px', 
    width: 750,
    height: 500,
    x: 0,
    y: 0,
  });

  const apiKey = tinyKey;

  let title = props.banner.id ? "Banner aanpassen" : "Nieuw banner maken";

  const handleCheckChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleDescriptionChange = (content, editor) => {
    setState({ ...state, description: content });
  };

  const onSelectCategory = (data, context) => {
    console.log(state.addedCategories, "addedCategories");
    setState({ ...state, selectedCategories: data });
    // remove __typename field from context
    // delete context.__typename;
    setState({
      ...state,
      addedCategories: [...state.addedCategories, context],
    });
  };

  const onRemoveCategory = (data, context) => {
    console.log(data, context, "removedCategories");
    setState({ ...state, selectedCategories: data });
    setState({
      ...state,
      removedCategories: [...state.removedCategories, context],
    });
  };

  const onDropImage = (image) => {
    try {
      console.log(image, "image");
      if (!image[0]) return;

      const file = image[image.length - 1];
      const objectUrl = URL.createObjectURL(file);
      const img = new Image();

      img.src = objectUrl;

      img.onload = () => {
        console.log(img.width, img.height, "image");
        if (img.width >= 750 && img.height >= 500) {
          console.log(objectUrl, "objectUrl");
          
          setIsPreview(true); // Enable preview
          setPreviewUrl(objectUrl); // Set preview URL
          setErrors(false);
          image.splice(0, 1);
          setIsCropper(true);
        } else {
          setIsPreview(false); // Disable preview
          setPreviewUrl(""); // Clear preview URL
          setErrors(true);
          image.splice(0, 1);
        }
        URL.revokeObjectURL(objectUrl); // Clean up
      };

      img.onerror = (error) => {
        console.log(error, "error");
        setIsPreview(false); // Disable preview
        setPreviewUrl(""); // Clear preview URL
        setErrors(true); // Show error for image loading issue
      };
      setTimeout(() => {
        //
      }, 300);


    } catch (err) {
      console.log(err, "error");
    }
  };

  const [initiatedDelete, setInitiatedDelete] = useState(false);


  const initiateDelete = () => {
    setInitiatedDelete(true);
  };

  const cancelDelete = () => {
    setInitiatedDelete(false);
  };

  const confirmDelete = () => {
    deleteBanner({ variables: { id: props.banner.id } });
    props.onClose();
  };

  const saveBanner = () => {
    let bannerID = "";

    console.log(state.image, props.banner.id, "image");

    if (props.banner.id) {
      bannerID = props.banner.id;
      state.removedCategories.forEach(function (removedCategory) {
        removeBannerFromCategory({
          variables: {
            bannerId: bannerID,
            categoryId: removedCategory.id,
          },
        });
      });
      state.addedCategories.forEach(function (addedCategory) {
        addBannerToCategory({
          variables: {
            bannerId: bannerID,
            categoryId: addedCategory.id,
          },
        });
      });
      console.log(state.image, "image upload2");
      if (state.image instanceof File) {
        // ImageUpload(state.image).then((r) => {
        //   var image = r.data.name;

          updateBanner({
            variables: {
              id: props.banner.id,
              description: state.description,
              activeStatus: state.activeStatus,
              image: state.image,
              categories: state.addedCategories
            },
          });
        // })
      } else {
        var image = state.image;
        updateBanner({
          variables: {
            id: props.banner.id,
            description: state.description,
            activeStatus: state.activeStatus,
            image: image,
            categories: state.addedCategories
          },
        });
      }
    } else {
      // ImageUpload(state.image).then((r) => {
      //   var image = r.data.name;
        // console.log(r.data.name, "image upload");
        createImage({
          variables: {
            location: state.image,
          },
        }).then((r) => {
          createBanner({
            variables: {
              description: state.description,
              activeStatus: state.activeStatus,
              image: state.image,
              categories: state.addedCategories
            },
          }).then((r) => {
            bannerID = r.data.createBanner.id;
            console.log({ r });
            state.removedCategories.forEach(function (removedCategory) {
              removeBannerFromCategory({
                variables: {
                  bannerId: bannerID,
                  categoryId: removedCategory.id,
                },
              });
            });
            state.addedCategories.forEach(function (addedCategory) {
              addBannerToCategory({
                variables: {
                  bannerId: bannerID,
                  categoryId: addedCategory.id,
                },
              });
            });
            props.onClose();
          });
        });
      // })
    }

    // state.image.forEach((image) => {
    // trackPromise(
    // );
    // });

    props.onClose();
  };


  const handleCropComplete = async () => {
    console.log(imgRef.current, crop.width, crop.height, "handlecrop")
    setCompletedCrop(crop);

    if (imgRef.current && crop.width && crop.height) {
      console.log("true")
      const canvas = document.createElement('canvas');
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        imgRef.current,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(async (blob) => {
        console.log(blob, "blob");
        if (blob) {
          try {
          // Upload the cropped image
          ImageUpload(blob, true).then((r) => {
            console.log(r.data.name )
            setState({ ...state, image: r.data.name });
            setIsCropper(false)
          })
        

            console.log('Image uploaded successfully');
          } catch (error) {
            console.error('Error uploading image:', error);
          }
        }
      }, 'image/jpeg');
    }
    console.log("end")
  };


  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={classes.parent}
      >
        <Paper className={classes.prodmodalStyle}>
          {isCropper ? (
            <>
              
              <ReactCrop
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                ruleOfThirds
                minWidth={750}
                minHeight={500}
                maxWidth={750}
                maxHeight={500}
                keepSelection
                disabled={false} // Allow movement but not resizing
              >
                <img ref={imgRef} src={previewUrl} alt="Crop preview" style={{ width: '100%' }} />
              </ReactCrop>

              <Button
                  variant="contained"
                  color="secondary"
                  className={classes.input}
                  onClick={() => setIsCropper(false)}                             
                  type="button"
                  style={{ marginRight: 20 }} 
                >
                  <Translate>annuleren</Translate>
                  
                </Button>
              <Button
                  variant="contained"
                  color="primary"
                  className={classes.input}
                  onClick={handleCropComplete}
                  type="button"
                >
                  <Translate>Gewas</Translate>                  
                </Button>
            </>
          ) : (
            <>
              <h2>{title}</h2>
              <form
                encType="multipart/form-data"
                onSubmit={(event) => {
                  event.preventDefault();
                  saveBanner();
                }}
              >
                <br />
                <FormControl className={classes.input}>
                  <FormLabel
                    className={classes.lastInput}
                    id="demo-controlled-radio-buttons-group">
                    categorieën
                  </FormLabel>
                  <CategoriesSelector
                    onSelectCategory={onSelectCategory}
                    onRemoveCategory={onRemoveCategory}
                    selectedCategories={state.selectedCategories}
                  />
                </FormControl>
                <Editor
                  apiKey={apiKey}
                  value={state.description}
                  init={{
                    height: 250,
                    marginTop: 10,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      // eslint-disable-next-line no-multi-str
                      "undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={handleDescriptionChange}
                />
                <FormControlLabel
                  className={`${classes.input} ${classes.lastInput}`}
                  control={
                    <Switch
                      checked={state.activeStatus}
                      onChange={handleCheckChange}
                      name="activeStatus"
                      label="Gepubliceerd"
                    />
                  }
                  label="Gepubliceerd"
                />
                  {isPreview && state.image ? (<> <br /> <img src={"https://shop.zeghetmetduiten.nl/images/" + (state.image ? state.image : '')} height={250} /></>) : props.banner.id && (<> <br /> <img src={"https://shop.zeghetmetduiten.nl/images/" + (props.banner.image ? props.banner.image : '')} height={250} /></>)}
                <ImageUploader
                  withIcon={true}
                  onChange={onDropImage}
                  imgExtension={[".jpg", ".png"]}
                  maxFileSize={10024 * 1024 * 1024}
                  withPreview={false}

                />
                {errors && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Selected image does not meet the required resolution (min 750x500).
                  </span>
                )}
                {props.banner && (
                  <ConfirmationRequiredButton
                    initiated={initiatedDelete}
                    cancel={cancelDelete}
                    initiate={initiateDelete}
                    confirm={confirmDelete}
                    confirmationMessage={
                      "Weet je zeker dat je dit banner wilt verwijderen? Alle gerelateerde informatie (inclusief bestellingen) word ook verwijderd. Dit is ten zeerste afgeraden, niet publiceren is vrijwel altijd een betere optie"
                    }
                    cancelMessage={"Annuleren"}
                    confirmMessage={"Verwijderen"}
                    initialMessage={"Verwijderen"}
                  />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.input}
                  type="submit"
                >
                  Opslaan
                </Button>
              </form>
            </>
          )}

        </Paper>


      </Modal>
    </div>
  );
}
