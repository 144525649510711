import React, { useState } from "react";
import UsersHeader from "../../components/UsersHeader";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import UsersLanding from "./UsersLanding";
import SingleCategory from "./SingleCategory";
import SingleProduct from "./SingleProduct";
import Over from "./Over";
import Footer from "../../components/Footer";
import { connect } from "react-redux";
import Cart from "./Cart";
import BestellingGeslaagd from "./BestellingGeslaagd";
import { useQuery } from "@apollo/react-hooks";
import { CUSTOMER_BY_CODE } from "../../queries-mutations/customers";
import moment from "moment";
import { logoutUser } from "../../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Translate } from "react-auto-translate";

function Users(props) {
  let match = useRouteMatch();
  let history = useHistory();

  if (!props.userInformation.auth) {
    history.push("/");
  }
  const { loading, error, data } = useQuery(CUSTOMER_BY_CODE, {
    variables: { code: props.userInformation.code }
  });

  let tooLate = false;

  if (error) {
    props.logoutUserForced();
    return <p>Something went wrong</p>;
  } else if (loading) {
    return <CircularProgress />;
  } else if (data) {
    if (data.customerByCode == null) {
      props.logoutUserForced();
      return (
        <p>
          <Translate>Er ging iets mis</Translate>
        </p>
      );
    }
    let endDate = moment(data?.customerByCode?.company?.endDate, "YYYY-MM-DD");
    if (moment().isAfter(endDate) || data?.customerByCode?.duiten < 1) {
      tooLate = true;
    }
    return (
      <div>
        <UsersHeader />
        <div style={{ minHeight: "calc(100vh - 160px)" }}>
        {/* <div style={{ minHeight: "85vh" }}> */}
          <Switch>
            <Route path={`${match.path}/over`}>
              <Over />
            </Route>
            {!tooLate && (
              <Route path={`${match.path}/bestelling-geslaagd`}>
                <BestellingGeslaagd />
              </Route>
            )}
            {!tooLate && (
              <Route path={`${match.path}/cart`}>
                <Cart />
              </Route>
            )}
            {!tooLate && (
              <Route path={`${match.path}/product/:id`}>
                <SingleProduct />
              </Route>
            )}
            {!tooLate && (
              <Route path={`${match.path}/cat/:id`}>
                <SingleCategory />
              </Route>
            )}
            <Route path={match.path}>
              {tooLate ? <BestellingGeslaagd /> : <UsersLanding />}
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userInformation } = state;
  return { userInformation };
};

const mapDispatchToProps = dispatch => {
  return {
    logoutUserForced: () => {
      dispatch(logoutUser());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
